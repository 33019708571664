import { browser, dev } from "$app/environment"
import { Axiom } from '@axiomhq/js';

// import { DataDogTransport, type DDTransportOptions } from 'datadog-transport-common'
import {
  consoleTransport,
  createRootLogger,
  type JsonObject,
  type Logger,
  type TransportMethod
} from "./simple-json-logger";

import { PUBLIC_SUPABASE_ANON_KEY, PUBLIC_SUPABASE_URL, PUBLIC_DEPLOYMENT_ENV } from "$env/static/public"
import { readable, get } from "svelte/store";
import { type PostgrestSingleResponse } from "@supabase/supabase-js"


// const DD_API_KEY = "ce850cda6b86985e0386278d099e5241"

// const ddOptions: DDTransportOptions = {
//   ddClientConf: {
//     authMethods: {
//       apiKeyAuth: DD_API_KEY
//     }
//   },
//   ddsource: browser ? "browser" : "server",
//   ddServerConf: {
//     site: "us3.datadoghq.com"
//   },
//   service: "website",
//   ddtags: `env:${PUBLIC_DEPLOYMENT_ENV}`,
// }

// const transport = new DataDogTransport(ddOptions)

// export const ddTransport: TransportMethod = (level, message) => {
//   transport.processLog({level, ...message})
// }

const axiom = new Axiom({
  //token: "xaat-b8febb20-b391-4c17-8a2b-97c4695fd71d" - test only
  token: "xaat-27fa16ca-fa3f-4d75-90c4-647db88f8c8d"
});

function mkAxiomTransport(on_error?: (err: unknown) => void): TransportMethod {
  return (level, message) => {
    const data = {
      level,
      context: message.context,
      payload: JSON.stringify(message.payload)
    }

    axiom.ingest("website", [data])
    axiom.flush().catch(err => { if (on_error) on_error(err) } )
  }
}

function mkBrowserInstance(): Logger {
  return createRootLogger([
    consoleTransport,
  ], {env: PUBLIC_DEPLOYMENT_ENV})
}

async function mkServerInstance(): Promise<Logger> {
  console.log("Server-side logging, with DB export")

  const { createClient } = await import("@supabase/supabase-js")

  const supabaseServiceRole = createClient(
    PUBLIC_SUPABASE_URL,
    PUBLIC_SUPABASE_ANON_KEY,
    { auth: { persistSession: false } },
  )
  
  const dbTransport: TransportMethod = async (level, message) => {
    const data = {
      level,
      context: message.context,
      payload: JSON.stringify(message.payload)
    }
    supabaseServiceRole
      .from("metalogging")
      .insert({
        level,
        context: message.context,
        payload: message.payload,
      })
      .then(result => {
        if (result.error) {
          console.error(result.error)
        }
      })
  }

  const axiomTransport = mkAxiomTransport( err =>
    dbTransport("meta", {
      context: {
        env: PUBLIC_DEPLOYMENT_ENV,
        source: "server"
      },
      payload: JSON.stringify(err)
    })
  )

  return createRootLogger([
    consoleTransport,
    dbTransport,
    // ddTransport,
    axiomTransport,
  ], {env: PUBLIC_DEPLOYMENT_ENV})  
}

const loggerInstance = browser ? mkBrowserInstance() : await mkServerInstance()

const loggerReadable = readable<Logger>(loggerInstance)

const log = get(loggerReadable)


export function loggerForPath(path: string) {
  if (path) {
    const splitPoint = path.indexOf("/src/")
    const source = path.substring(splitPoint)
    return log.child({ source })
  } else {
    return log
  }
}

type ResponseFailure = {
  data: null
  error: string | JsonObject
}

type ResponseSuccess = {
  data: string | JsonObject
  error: null
}

type ResponseT = ResponseFailure | ResponseSuccess

export function logResponse<R extends ResponseT>(response: R, customLogger?: Logger): R {
  const { data, error } = response
  if (error) {
    (customLogger ?? log).error({ error })
  } else {
    (customLogger ?? log).debug({ data })
  }
  return response
}

export function logPostgresResponse<T>(response: PostgrestSingleResponse<T>, customLogger?: Logger): PostgrestSingleResponse<T> {
  const { data, error } = response
  if (error) {
    (customLogger ?? log).error({ error })
  } else {
    (customLogger ?? log).debug({ data })
  }
  return response
}