
export type JsonValue =
  | JsonObject
  | JsonValue[]
  | boolean
  | number
  | string
  | readonly JsonValue[]
  | null
  | undefined

/**
 * @see https://stackoverflow.com/a/77390832/368691
 * @public
 */
export type JsonObject = {
  [k: string]: JsonValue
}

type LogMethod = (payload: unknown) => void
type Message = { context: JsonObject, payload: unknown }
export type TransportMethod = (level: LogLevelName, message: Message) => void

export type LogLevelName =
  | 'trace'
  | 'debug'
  | 'info'
  | 'warn'
  | 'error'
  | 'fatal'
  | 'meta'

export const consoleTransport: TransportMethod = (
  level: LogLevelName,
  message: Message
) => {
  switch(level) { 
    case 'trace': { 
      console.trace(message) 
      break
    } 
    case 'debug': { 
      console.debug(message) 
      break
    } 
    case 'info': { 
      console.info(message) 
      break
    } 
    case 'warn': { 
        console.warn(message) 
        break
    } 
    case 'error': { 
        console.error(message) 
        break
    } 
    case 'fatal': { 
        console.error(message) 
        break
    }
  }
}

export interface Logger {
  child: (subContext: JsonObject) => Logger
  log:   TransportMethod

  trace: LogMethod
  debug: LogMethod
  info:  LogMethod
  warn:  LogMethod
  error: LogMethod
  fatal: LogMethod
}

class LoggerInstance implements Logger {
  private readonly context: JsonObject
  private readonly transports: TransportMethod[]

  constructor(context = {}, transports: TransportMethod[]) {
    if (!Array.isArray(transports) || transports.length === 0) {
      throw new Error('At least one transport is required')
    }
    if (!transports.every(t => typeof t === 'function')) {
      throw new Error('All transports must be functions')
    }
    this.context = Object.freeze({...context})
    this.transports = [...transports]
  }

  public child = (subContext: JsonObject) => new LoggerInstance({...this.context, ...subContext}, this.transports)

  public log: TransportMethod = (level: LogLevelName, message: Message) =>
    this.transports.forEach(t => t(level, message))

  public trace = (payload: unknown) => this.log("trace", {context: this.context, payload})
  public debug = (payload: unknown) => this.log("debug", {context: this.context, payload})
  public info  = (payload: unknown) => this.log("info", {context: this.context, payload})
  public warn  = (payload: unknown) => this.log("warn", {context: this.context, payload})
  public error = (payload: unknown) => this.log("error", {context: this.context, payload})
  public fatal = (payload: unknown) => this.log("fatal", {context: this.context, payload})
}


export function createRootLogger(
  transports: TransportMethod[] = [consoleTransport],
  context: JsonObject = {}
) {
  return new LoggerInstance(context, transports)
}